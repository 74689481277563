<template>
  <v-row justify="start">
    <v-dialog v-model="dialogDelete" persistent max-width="400px">
      <v-card class="rounded-max">
        <v-toolbar dense flat>
          <h2>Are You Sure?</h2>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="$emit('close')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-container>
          <p>
            Are you ABSOLUTELY SURE you wish to delete this User? This process
            cannot be undone.
          </p>
        </v-container>
        <v-card-actions class="">
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            class="text-capitalize"
            text
            @click="$emit('close')"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="!loading"
            color="red"
            class="text-capitalize"
            depressed
            dark
            @click="deleteItem"
            ><v-icon class="mr-2">mdi-delete</v-icon>
            <p class="ma-0">Delete</p></v-btn
          >
          <v-btn
            v-if="loading"
            color="red"
            class="text-capitalize"
            dark
            depressed
            ><v-progress-circular
              :size="25"
              class="mr-2"
              indeterminate
            ></v-progress-circular>
            Loading</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "deleteUser",
  data() {
    return {
      loading: false,
    };
  },
  props: ["dialogDelete", "dataItem", "role"],

  methods: {
    deleteItem() {
      this.loading = true;
      let data = {
        id: this.dataItem,
      };
      this.$store
        .dispatch("user/deleteUser", data)
        .then((data) => {
          if (data.status == "success") {
            this.$emit("close");
            this.$router.push(`/list/user/${this.role}`);
            Swal.fire({
              icon: "success",
              title: data.message,
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: data.message,
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000,
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          Swal.fire({
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000,
          });
        });
    },
  },
};
</script>

<style></style>
