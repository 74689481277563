<template>
  <div>
    <div class="pa-3" v-if="!loadingPage">
      <v-btn
        rounded
        :to="`/list/user/${$route.params.role}`"
        depressed
        class="mb-3"
        fab
        color="green"
        dark
        small
        ><v-icon>mdi-reply</v-icon></v-btn
      >
      <div v-if="user && person" class="_70">
        <section>
          <div class="d-flex align-center mb-3">
            <v-btn fab depressed class="mr-2" color="blue lighten-5"
              ><v-icon color="blue">mdi-account</v-icon></v-btn
            >
            <h3>Profile</h3>
          </div>
          <div style="margin-left: 65px">
            <UpdateAdmin
              v-bind:account="user.data[0]"
              v-bind:person="person"
              v-bind:loading="loadingProfile"
              v-if="role == 'data_control'"
              @update="updateItem"
            />
            <UpdateTf
              v-bind:account="user.data[0]"
              v-bind:person="person"
              v-bind:loading="loadingProfile"
              v-if="role == 'tf'"
              @update="updateItem"
            />
            <UpdateCounselor
              v-bind:account="user.data[0]"
              v-bind:person="person"
              v-bind:loading="loadingProfile"
              v-if="role == 'admin_assessment'"
              @update="updateItem"
            />
            <UpdateScholar
              v-bind:account="user.data[0]"
              v-bind:person="person"
              v-bind:loading="loadingProfile"
              v-if="role == 'scholar'"
              @update="updateItem"
            />
          </div>
        </section>
        <v-divider></v-divider>
        <section v-if="role !== 'scholar' && user">
          <ListAccess @refetch="getuser" />
        </section>
        <v-divider></v-divider>
        <section>
          <RedZone v-bind:account="user.data[0]" @refetch="getuser" />
        </section>
      </div>
    </div>
    <div v-if="loadingPage">
      <LoadingLg />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import ListAccess from "../../../components/UserManagement/Update/listAccess.vue";
import UpdateAdmin from "../../../components/UserManagement/Update/updateAdmin.vue";
import UpdateTf from "../../../components/UserManagement/Update/updateTf.vue";
import UpdateCounselor from "../../../components/UserManagement/Update/updateCounselor.vue";
import UpdateScholar from "../../../components/UserManagement/Update/updateScholar.vue";
import RedZone from "../../../components/UserManagement/Update/redZone.vue";
import LoadingLg from "../../../components/etc/loading-component/loadingLG.vue";

export default {
  name: "updateUser",
  computed: {
    ...mapState({
      user: (state) => state.user.list_user,
      access: (state) => state.user.list_access_user,
    }),
  },
  components: {
    ListAccess,
    UpdateAdmin,
    UpdateTf,
    UpdateCounselor,
    UpdateScholar,
    RedZone,
    LoadingLg,
  },
  data() {
    return {
      person: null,
      loadingPage: false,
      loadingProfile: false,
      calendar1: false,
      role: null,
    };
  },
  mounted() {
    this.role = this.$route.params.role;
    this.getuser(true);
  },
  methods: {
    getuser(mount) {
      if (mount) {
        this.loadingPage = true;
      }
      let include = "";
      switch (this.role) {
        case "data_control":
          include = "dataControl";
          break;
        case "tf":
          include = "tf";
          break;
        case "admin_assessment":
          include = "adminAssessment";
          break;
        case "scholar":
          include = "scholar";
          break;
      }
      let data = {
        id: this.$route.params.id,
        role: this.role,
        include: include,
      };
      this.$store.dispatch("user/listUser", data).then((data) => {
        this.person = data.data[0].relationships[0][include];
        setTimeout(() => (this.loadingPage = false), 1000);
      });
    },

    updateItem(body) {
      let role = this.role;
      let data = {
        role: role,
        body: body,
      };
      this.loadingProfile = true;
      this.$store
        .dispatch("user/updateUser", data)
        .then((data) => {
          this.loadingProfile = false;
          if (data.status == "success") {
            this.Swal("success", data.message);
          } else {
            this.Swal("error", data.message);
          }
        })
        .catch((err) => {
          this.loadingProfile = false;
          this.Swal("error", err.message);
        });
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top",
        toast: true,
        timer: 5000,
      });
    },
  },
};
</script>

<style scoped>
._70 {
  width: 70%;
}
</style>
