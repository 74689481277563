<template>
  <div>
    <div class="d-flex align-center mb-3">
      <v-btn fab depressed class="mr-2" color="green lighten-5"
        ><v-icon color="green">mdi-key</v-icon></v-btn
      >
      <h3>List Access</h3>
    </div>
    <div style="margin-left: 65px">
      <v-row>
        <v-col
          cols="4"
          v-if="
            $route.params.role !== 'tf' &&
            $route.params.role !== 'admin_assessment'
          "
        >
          <v-card class="px-3 rounded-lg" flat>
            <h3>Admin TF</h3>
            <v-radio-group v-model="selectedTf">
              <v-radio
                v-for="n in permission"
                :key="n"
                :label="n"
                :value="n"
              ></v-radio>
            </v-radio-group>
          </v-card>
        </v-col>
        <v-col cols="4" v-if="$route.params.role !== 'admin_assessment'">
          <v-card class="px-3 rounded-lg" flat>
            <h3>Admin Assessment</h3>
            <v-radio-group v-model="selectedCounselor">
              <v-radio
                v-for="n in permission"
                :key="n"
                :label="n"
                :value="n"
              ></v-radio>
            </v-radio-group>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="mr-3 px-3 rounded-lg" flat>
            <h3>Scholar</h3>
            <v-radio-group v-model="selectedScholar">
              <v-radio
                v-for="n in permission"
                :key="n"
                :label="n"
                :value="n"
              ></v-radio>
            </v-radio-group>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <footer class="d-flex justify-end my-3">
      <v-btn
        color="green"
        class="text-capitalize"
        @click="checkSelected"
        dark
        v-if="!loadingAccess"
        >Save</v-btn
      >
      <v-btn
        v-if="loadingAccess"
        color="green"
        class="text-capitalize"
        dark
        depressed
        ><v-progress-circular
          :size="25"
          class="mr-2"
          indeterminate
        ></v-progress-circular>
        Loading</v-btn
      >
    </footer>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "listAccess",
  data() {
    return {
      loadingAccess: false,
      permission: ["No Access", "Read Only", "Full Access"],
      selected: [],
      selectedTf: null,
      selectedCounselor: null,
      selectedScholar: null,
      AccessTf: [],
      AccessCounselor: [],
      AccessScholar: [],
      id: null,
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getAcccessedUser(this.id);
  },
  methods: {
    getAcccessedUser(id) {
      this.$store.dispatch("user/listUserAcces", id).then((data) => {
        this.selected = data.data;
        let tf = data.data.tf;
        if (!tf.length) {
          this.selectedTf = "No Access";
        } else if (tf.length == 1) {
          this.selectedTf = "Read Only";
        } else {
          this.selectedTf = "Full Access";
        }
        let Counselor = data.data.admin_assessment;
        if (!Counselor.length) {
          this.selectedCounselor = "No Access";
        } else if (Counselor.length == 1) {
          this.selectedCounselor = "Read Only";
        } else {
          this.selectedCounselor = "Full Access";
        }
        let Scholar = data.data.scholar;
        if (!Scholar.length) {
          this.selectedScholar = "No Access";
        } else if (Scholar.length == 1) {
          this.selectedScholar = "Read Only";
        } else {
          this.selectedScholar = "Full Access";
        }
      });
    },
    checkSelected() {
      if (this.selectedTf == "No Access") {
        this.AccessTf = [];
      } else if (this.selectedTf == "Read Only") {
        this.AccessTf = ["View TF List"];
      } else {
        this.AccessTf = [
          "View TF List",
          "Create TF Account",
          "Edit TF Account",
        ];
      }
      if (this.selectedCounselor == "No Access") {
        this.AccessCounselor = [];
      } else if (this.selectedCounselor == "Read Only") {
        this.AccessCounselor = ["View Admin Assessment List"];
      } else {
        this.AccessCounselor = [
          "View Admin Assessment List",
          "Create Admin Assessment Account",
          "Edit Admin Assessment Account",
        ];
      }
      if (this.selectedScholar == "No Access") {
        this.AccessScholar = [];
      } else if (this.selectedScholar == "Read Only") {
        this.AccessScholar = ["View Scholar List"];
      } else {
        this.AccessScholar = [
          "View Scholar List",
          "Create Scholar Account",
          "Edit Scholar Account",
        ];
      }

      let selected = [
        ...this.AccessTf,
        ...this.AccessCounselor,
        ...this.AccessScholar,
      ];
      this.asignAccess(selected);
    },
    asignAccess(selected) {
      this.loadingAccess = true;
      let data = {
        id: this.id,
        data: selected,
      };
      this.$store
        .dispatch("user/assignAccess", data)
        .then((data) => {
          if (data.status == "success") {
            this.$emit("refetch");
            Swal.fire({
              icon: "success",
              title: data.message,
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: data.message,
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000,
            });
          }
          this.loadingAccess = false;
        })
        .catch((err) => {
          console.log(err.message);
          Swal.fire({
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000,
          });
        });
    },
  },
};
</script>

<style></style>
