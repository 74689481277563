<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">Nama Lengkap</v-col>
      <v-col cols="12" md="9"
        ><v-text-field
          v-model="account.nama_lengkap"
          hide-details="auto"
          label="Nama lengkap"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">Email</v-col>
      <v-col cols="12" md="9"
        ><v-text-field
          v-model="account.email"
          hide-details="auto"
          label="Email"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">Alamat</v-col>
      <v-col cols="12" md="9"
        ><v-text-field
          v-model="person.alamat"
          hide-details="auto"
          label="Alamat"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">No Hp</v-col>
      <v-col cols="12" md="9"
        ><v-text-field
          v-model="person.no_hp"
          hide-details="auto"
          label="No Hp"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">Lembaga</v-col>
      <v-col cols="12" md="9"
        ><v-text-field
          v-model="person.lembaga"
          hide-details="auto"
          label="Lembaga"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <footer class="d-flex justify-end my-5">
      <v-btn
        color="green"
        class="text-capitalize"
        dark
        v-if="!loading"
        @click="updateItem"
        >Save</v-btn
      >
      <v-btn v-if="loading" color="green" class="text-capitalize" dark depressed
        ><v-progress-circular
          :size="25"
          class="mr-2"
          indeterminate
        ></v-progress-circular>
        Loading</v-btn
      >
    </footer>
  </div>
</template>

<script>
export default {
  name: "updateCounselor",
  props: ["account", "person", "loading"],
  data() {
    return {
      calendar1: false,
    };
  },
  methods: {
    updateItem() {
      let body = {
        id: this.account.id,
        nama_lengkap: this.account.nama_lengkap,
        email: this.account.email,
        no_hp: this.person.no_hp,
        alamat: this.person.alamat,
        lembaga: this.person.pic,
      };
      this.$emit("update", body);
    },
  },
};
</script>

<style></style>
