<template>
  <div>
    <div class="d-flex align-center mb-3">
      <v-btn fab depressed class="mr-2" color="red lighten-5"
        ><v-icon color="red">mdi-alert</v-icon></v-btn
      >
      <h3 class="red--text">Red Zone</h3>
    </div>
    <div
      class="pa-3 grey lighten-2"
      style="margin-left: 65px"
      v-if="account.status == 1"
    >
      <h4>Deactivate User</h4>
      <p class="ma-0">- User will not be able to access the account</p>
      <p class="ma-0">- You can reactivate it on this page</p>
      <footer class="d-flex justify-end my-3">
        <v-btn
          color="green"
          class="text-capitalize"
          dark
          v-if="!loadingDisable"
          @click="disableUser"
        >
          deactivate</v-btn
        >
        <v-btn
          v-if="loadingDisable"
          color="green"
          class="text-capitalize"
          dark
          depressed
          ><v-progress-circular
            :size="25"
            class="mr-2"
            indeterminate
          ></v-progress-circular>
          Loading</v-btn
        >
      </footer>
    </div>
    <div
      class="pa-3 grey lighten-2"
      style="margin-left: 65px"
      v-if="account.status == 0"
    >
      <h4>Aktifkan User</h4>
      <p class="ma-0">- Users will be able to access the account</p>
      <p class="ma-0">- You can deactivate it again on this page</p>
      <footer class="d-flex justify-end my-3">
        <v-btn
          color="green"
          class="text-capitalize"
          dark
          v-if="!loadingDisable"
          @click="disableUser"
          >activate</v-btn
        >
        <v-btn
          v-if="loadingDisable"
          color="green"
          class="text-capitalize"
          dark
          depressed
          ><v-progress-circular
            :size="25"
            class="mr-2"
            indeterminate
          ></v-progress-circular>
          Loading</v-btn
        >
      </footer>
    </div>
    <div class="pa-3 grey lighten-2 mt-3" style="margin-left: 65px">
      <h4>Delete User</h4>
      <p class="ma-0">- User will be permanently deleted immediately</p>

      <footer class="d-flex justify-end my-3">
        <v-btn
          color="red"
          class="text-capitalize"
          dark
          v-if="!loadingDelete"
          @click="dialogDelete = true"
          >Delete</v-btn
        >
        <v-btn
          v-if="loadingDelete"
          color="red"
          class="text-capitalize"
          dark
          depressed
          ><v-progress-circular
            :size="25"
            class="mr-2"
            indeterminate
          ></v-progress-circular>
          Loading</v-btn
        >
      </footer>
    </div>
    <div class="pa-2">
      <DeleteUser
        @close="closeDialog"
        v-bind:dialogDelete="dialogDelete"
        v-bind:role="$route.params.role"
        v-bind:dataItem="account.id"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import DeleteUser from "../../../views/UserManagement/Delete/deleteUser.vue";

export default {
  name: "redzone",
  props: ["account"],
  components: { DeleteUser },
  data() {
    return {
      dialogDelete: false,
      loadingDelete: false,
      loadingDisable: false,
    };
  },
  mounted() {
  },
  methods: {
    disableUser() {
      this.loadingDisable = true;
      let data = {
        id: this.account.id,
        status: this.account.status == 1 ? 0 : 1,
      };
    
      this.$store
        .dispatch("user/updateAccount", data)
        .then((data) => {
          this.$router.push(`/list/user/${this.$route.params.role}`)
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000,
          });
          this.loadingDisable = false;
          this.$emit("refetch");
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000,
          });
          this.loadingDisable = false;
        });
    },
    closeDialog() {
      this.dialogDelete = false;
    },
  },
};
</script>

<style></style>
