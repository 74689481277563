<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">Nama Lengkap</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="account.nama_lengkap"
          hide-details="auto"
          label="Nama lengkap"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Tanggal Lahir</v-col>
      <v-col cols="12" md="8">
        <v-menu
          v-model="calendar1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="person.tgl_lahir"
              label="Tanggal Lahir"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="person.tgl_lahir"
            @input="calendar1 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Jenis Kelamin</v-col>
      <v-col cols="12" md="8">
        <v-radio-group
          v-model="person.jenis_kelamin"
          row
          hide-details="auto"
          class="mt-0"
        >
          <v-radio label="Laki-laki" value="Laki-laki"></v-radio>
          <v-radio label="Perempuan" value="Perempuan"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Email</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="account.email"
          hide-details="auto"
          label="Email"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <!-- <v-row>
      <v-col cols="12" md="4">Alamat</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.alamat"
          hide-details="auto"
          label="Alamat"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row> -->

    <v-row>
      <v-col cols="12" md="4">Nim</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.nim"
          hide-details="auto"
          label="Nim"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Nik</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.nik"
          hide-details="auto"
          label="Nik"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">NO. TELADAN</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.no_tf"
          hide-details="auto"
          label="No TF"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Universitas</v-col>
      <v-col cols="12" md="8">
        <!-- <v-text-field
          v-model="person.universitas"
          hide-details="auto"
          label="Universitas"
          required
          dense
        ></v-text-field
      > -->
        <v-select
          v-model="person.universitas"
          :items="listUniv"
          menu-props="auto"
          label="Universitas"
          hide-details
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Fakultas</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.fakultas"
          hide-details="auto"
          label="Fakultas"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Jurusan</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.jurusan"
          hide-details="auto"
          label="Jurusan"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Tahun PTN</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.tahun_ptn"
          hide-details="auto"
          label="Tahun PTN"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Tahun TF</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.tahun_tf"
          hide-details="auto"
          label="Tahun TF"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Program</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.program"
          hide-details="auto"
          label="Program"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Semester Daftar</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.semester_daftar"
          hide-details="auto"
          label="Semester Daftar"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Semester Sekarang</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.semester_sekarang"
          hide-details="auto"
          label="Semester Sekarang"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Assessment</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.assessment"
          hide-details="auto"
          label="Assessment"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Grade</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.grade"
          hide-details="auto"
          label="Grade"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Semester 1</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.semester1"
          hide-details="auto"
          label="Semester 1"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Semester 2</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.semester2"
          hide-details="auto"
          label="Semester 2"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Semester 3</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.semester3"
          hide-details="auto"
          label="Semester 3"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Semester 4</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.semester4"
          hide-details="auto"
          label="Semester 4"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Semester 5</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.semester5"
          hide-details="auto"
          label="Semester 5"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Semester 6</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.semester6"
          hide-details="auto"
          label="Semester 6"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Semester 7</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.semester7"
          hide-details="auto"
          label="Semester 7"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Semester 8</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.semester8"
          hide-details="auto"
          label="Semester 8"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Provinsi</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.provinsi"
          hide-details="auto"
          label="Provinsi"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Kota</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.kota"
          hide-details="auto"
          label="Kota"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Kode Pos</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.kode_pos"
          hide-details="auto"
          label="Kode Pos"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Tempat Lahir</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.tempat_lahir"
          hide-details="auto"
          label="Tempat Lahir"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Agama</v-col>
      <v-col cols="12" md="8">
        <!-- <v-text-field
          v-model="person.agama"
          hide-details="auto"
          label="Agama"
          required
          dense
        ></v-text-field
      > -->

        <v-select
          v-model="person.agama"
          :items="listReligion"
          menu-props="auto"
          label="Agama"
          hide-details
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Etnis</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.etnis"
          hide-details="auto"
          label="Etnis"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Grouping Fakultas</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.grouping_fakultas"
          hide-details="auto"
          label="Grouping Fakultas"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Nama Rekening</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.nama_rekening"
          hide-details="auto"
          label="Nama Rekening"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Nama Bank</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.nama_bank"
          hide-details="auto"
          label="Nama Bank"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">No Rekening</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.no_rekening"
          hide-details="auto"
          label="No Rekening"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Jumlah Tunjangan Perbulan</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.jumlah_tunjangan_bulanan"
          hide-details="auto"
          label="Jumlah Tunjangan Perbulan"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Tagihan UKT</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.tagihan_ukt"
          hide-details="auto"
          label="Tagihan UKT"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Ukuran Kaos</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.ukuran_kaos"
          hide-details="auto"
          label="Ukuran Kaos"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">Tsg</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.tsg"
          hide-details="auto"
          label="tsg"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <!-- =============================================NEW FORM ============================================================================= -->
    <v-row>
      <v-col cols="12" md="4">Kode Unik</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.kode_unik"
          hide-details="auto"
          label="Kode Unik"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Jalur Masuk Ptn</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.jalur_masuk_ptn"
          hide-details="auto"
          label="Jalur Masuk Ptn"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">No Wa</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.no_hp_wa"
          hide-details="auto"
          label="No Wa"
          type="number"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Kelurahan Desa</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.kelurahan_desa"
          hide-details="auto"
          label="Kelurahan Desa"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Negara</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.negara"
          hide-details="auto"
          label="Negara"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Akun Sosial Media</v-col>
      <v-col cols="12" md="8">
        <div class="scope_lang">
          <v-row
            v-for="(item, idx) in person.akun_sosial_media"
            :key="`sosmed-${idx}`"
          >
            <v-col cols="3">
              {{ item.name }}
            </v-col>
            <v-col cols="9">
              <v-text-field
                v-model="item.value"
                hide-details="auto"
                :placeholder="item.name"
                required
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Nama Ayah Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.nama_ayah_wali"
          hide-details="auto"
          label="Nama Ayah Wali"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Tempat Lahir Ayah Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.tempat_lahir_ayah_wali"
          hide-details="auto"
          label="Tempat Lahir Ayah Wali"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Tanggal Lahir Ayah Wali</v-col>
      <v-col cols="12" md="8"
        ><v-menu
          v-model="calendar2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="person.tanggal_lahir_ayah_wali"
              label="Tanggal Lahir Ayah Wali"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="person.tanggal_lahir_ayah_wali"
            @input="calendar2 = false"
          ></v-date-picker> </v-menu
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Pekerjaan Ayah Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.pekerjaan_ayah_wali"
          hide-details="auto"
          label="Pekerjaan Ayah Wali"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Penghasilan Kotor Perbulan Ayah Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.penghasilan_kotor_perbulan_ayah_wali"
          hide-details="auto"
          label="Penghasilan Kotor Perbulan Ayah Wali"
          required
          type="number"
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Pendidikan Terakhir Ayah Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.pendidikan_terakhir_ayah_wali"
          hide-details="auto"
          label="Pendidikan Terakhir Ayah_wali"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Email Ayah Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.email_ayah_wali"
          hide-details="auto"
          label="Email Ayah Wali"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">No Hp Ayah Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.no_hp_ayah_wali"
          hide-details="auto"
          label="No Hp Ayah Wali"
          type="number"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Nama Ibu Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.nama_ibu_wali"
          hide-details="auto"
          label="Nama Ibu Wali"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Tempat Lahir Ibu Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.tempat_lahir_ibu_wali"
          hide-details="auto"
          label="Tempat Lahir Ibu Wali"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Tanggal Lahir Ibu Wali</v-col>
      <v-col cols="12" md="8"
        ><v-menu
          v-model="calendar3"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="person.tanggal_lahir_ibu_wali"
              label="Tanggal Lahir Ibu Wali"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="person.tanggal_lahir_ibu_wali"
            @input="calendar3 = false"
          ></v-date-picker> </v-menu
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Pekerjaan Ibu Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.pekerjaan_ibu_wali"
          hide-details="auto"
          label="Pekerjaan Ibu Wali"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Penghasilan Kotor Perbulan Ibu Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.penghasilan_kotor_perbulan_ibu_wali"
          hide-details="auto"
          label="Penghasilan Kotor Perbulan Ibu Wali"
          required
          type="number"
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Pendidikan Terakhir Ibu Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.pendidikan_terakhir_ibu_wali"
          hide-details="auto"
          label="Pendidikan Terakhir Ibu Wali"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Email Ibu Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.email_ibu_wali"
          hide-details="auto"
          label="Email Ibu Wali"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">No Hp Ibu Wali</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.no_hp_ibu_wali"
          hide-details="auto"
          label="No Hp Ibu Wali"
          required
          type="number"
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Jumlah Tanggungan Orangtua</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.jumlah_tanggungan_orangtua"
          hide-details="auto"
          label="Jumlah Tanggungan Orangtua"
          required
          type="number"
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Prestasi</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.prestasi"
          hide-details="auto"
          label="Prestasi"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Kegiatan Organisasi</v-col>
      <v-col cols="12" md="8"
        ><v-text-field
          v-model="person.kegiatan_organisasi"
          hide-details="auto"
          label="Kegiatan Organisasi"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">Kemampuan Bahasa Asing</v-col>
      <v-col cols="12" md="8">
        <div
          class="scope_lang"
          v-for="(item, idx) in person.kemampuan_bahasa_asing"
          :key="`val${idx}`"
        >
          <v-row>
            <v-col cols="4"> Bahasa </v-col>
            <v-col cols="8">
              <v-text-field
                hide-details="auto"
                v-model="item.bahasa"
                placeholder="Bahasa"
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4"> Memiliki Sertifikasi </v-col>
            <v-col cols="8">
              <v-text-field
                hide-details="auto"
                v-model="item['Memiliki Sertifikasi']"
                placeholder="Memiliki Sertifikasi "
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4"> Jenis Tes </v-col>
            <v-col cols="8">
              <v-text-field
                hide-details="auto"
                v-model="item['Jenis Tes']"
                placeholder="Jenis Tes"
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4"> Skor </v-col>
            <v-col cols="8">
              <v-text-field
                hide-details="auto"
                v-model="item.Skor"
                placeholder="Skor"
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4"> Tahun Srtifikat </v-col>
            <v-col cols="8">
              <v-text-field
                hide-details="auto"
                v-model="item['Tahun Srtifikat']"
                placeholder="Tahun Srtifikat"
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4"> No. Sertifikat </v-col>
            <v-col cols="8">
              <v-text-field
                hide-details="auto"
                v-model="item['No. Sertifikat']"
                placeholder="No. Sertifikat"
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4"> Nama Lembaga Bahasa </v-col>
            <v-col cols="8">
              <v-text-field
                hide-details="auto"
                v-model="item['Nama Lembaga Bahasa']"
                placeholder="Nama Lembaga Bahasa "
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4"> Masa Berlaku </v-col>
            <v-col cols="8">
              <v-text-field
                hide-details="auto"
                v-model="item['Masa Berlaku']"
                placeholder="Masa Berlaku"
                required
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row v-for="(item, idx) in scan" :key="idx">
      <v-col cols="12" md="4">{{ item.label }}</v-col>
      <v-col cols="12" md="8"
        ><v-file-input
          v-model="item.file"
          class="ma-0 pa-0"
          hide-details="auto"
          :label="item.label"
        ></v-file-input
      ></v-col>
    </v-row>
    <footer class="d-flex justify-end my-5">
      <v-btn
        color="green"
        class="text-capitalize"
        dark
        v-if="!loading"
        @click="updateItem"
        >Save</v-btn
      >
      <v-btn v-if="loading" color="green" class="text-capitalize" dark depressed
        ><v-progress-circular
          :size="25"
          class="mr-2"
          indeterminate
        ></v-progress-circular>
        Loading</v-btn
      >
    </footer>
  </div>
</template>

<script>
export default {
  name: "updateScholar",
  props: ["account", "person", "loading"],
  data() {
    return {
      calendar1: false,
      calendar2: false,
      calendar3: false,
      akun_sosial_media: [],
      listUniv: [
        "Institut Pertanian Bogor",
        "Institut Teknologi Bandung",
        "Universitas Andalas",
        "Universitas Brawijaya",
        "Universitas Diponegoro",
        "Universitas Gadjah Mada",
        "Universitas Indonesia",
        "Universitas Riau",
        "Universitas Sumatera Utara",
      ],
      listReligion: [
        "Buddha",
        "Hindu",
        "Islam",
        "Katolik",
        "Konghucu",
        "Kristen",
      ],
      scan: [
        {
          label: "Scan Ktm",
          name: "ktm",
          type: "file",
          file: null,
        },
        {
          label: "Scan Ktp",
          name: "ktp",
          type: "file",
          file: null,
        },
        {
          label: "Scan Kk",
          name: "kk",
          type: "file",
          file: null,
        },
        {
          label: "Scan Slip Ayah Wali",
          name: "slip_ayah_wali",
          type: "file",
          file: null,
        },
        {
          label: "Scan Slip Ibu Wali",
          name: "slip_ibu_wali",
          type: "file",
          file: null,
        },
        {
          label: "Scan Rapor",
          name: "rapor",
          type: "file",
          file: null,
        },
        {
          label: "Scan Ijazah",
          name: "ijazah",
          type: "file",
          file: null,
        },
        {
          label: "Scan Sertifikat Prestasi",
          name: "sertifikat_prestasi",
          type: "file",
          file: null,
        },
        {
          label: "Scan Sertifikat Organisasi",
          name: "sertifikat_organisasi",
          type: "file",
          file: null,
        },
        {
          label: "Scan Sertifikat Bahasa Asing",
          name: "sertifikat_bahasa_asing",
          type: "file",
          file: null,
        },
        {
          label: "Scan Pas Foto",
          name: "pas_foto",
          type: "file",
          file: null,
        },
      ],
    };
  },
  methods: {
    updateItem() {
      let body = null;
      this.person["id"] = this.account.id;
      this.person["nama_lengkap"] = this.account.nama_lengkap;
      body = this.person;
      let form = new FormData();
      for (let key in body) {
        if (
          key != "scan_ktm" &&
          key != "scan_ktp" &&
          key != "scan_kk" &&
          key != "scan_slip_ayah_wali" &&
          key != "scan_slip_ibu_wali" &&
          key != "scan_ijazah" &&
          key != "scan_sertifikat_prestasi" &&
          key != "scan_rapor" &&
          key != "scan_sertifikat_organisasi" &&
          key != "scan_sertifikat_bahasa_asing" &&
          key != "scan_pas_foto"
        ) {
          if (body[key] !== null) {
            if (
              key === "akun_sosial_media" ||
              key === "kemampuan_bahasa_asing"
            ) {
              form.append(key, JSON.stringify(body[key]));
            } else {
              form.append(key, body[key]);
            }
          }
        }
      }
      this.$emit("update", form);
    },
  },
};
</script>

<style></style>
