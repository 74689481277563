<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">Nama Lengkap</v-col>
      <v-col cols="12" md="9"
        ><v-text-field
          v-model="account.nama_lengkap"
          hide-details="auto"
          label="Nama lengkap"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">Email</v-col>
      <v-col cols="12" md="9"
        ><v-text-field
          v-model="account.email"
          hide-details="auto"
          label="Email"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">Jenis Kelamin</v-col>
      <v-col cols="12" md="9">
        <v-radio-group
          v-model="person.jenis_kelamin"
          row
          hide-details="auto"
          class="mt-0"
        >
          <v-radio label="Laki-laki" value="laki-laki"></v-radio>
          <v-radio label="Perempuan" value="perempuan"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">Alamat</v-col>
      <v-col cols="12" md="9"
        ><v-text-field
          v-model="person.alamat"
          hide-details="auto"
          label="alamat"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">No Hp</v-col>
      <v-col cols="12" md="9"
        ><v-text-field
          v-model="person.no_hp"
          hide-details="auto"
          label="No Hp"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">Jabatan</v-col>
      <v-col cols="12" md="9"
        ><v-text-field
          v-model="person.posisi"
          hide-details="auto"
          label="Posisi"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="12" md="3">Profesi</v-col>
      <v-col cols="12" md="9"
        ><v-text-field
          v-model="person.profesi"
          hide-details="auto"
          label="Profesi"
          required
          dense
        ></v-text-field
      ></v-col>
    </v-row> -->
    <v-row>
      <v-col cols="12" md="3">Tanggal Lahir</v-col>
      <v-col cols="12" md="9">
        <v-menu
          v-model="calendar1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="person.tanggal_lahir"
              label="Tanggal Lahir"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="person.tanggal_lahir"
            @input="calendar1 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <footer class="d-flex justify-end my-5">
      <v-btn
        color="green"
        class="text-capitalize"
        dark
        v-if="!loading"
        @click="updateItem"
        >Save</v-btn
      >
      <v-btn v-if="loading" color="green" class="text-capitalize" dark depressed
        ><v-progress-circular
          :size="25"
          class="mr-2"
          indeterminate
        ></v-progress-circular>
        Loading</v-btn
      >
    </footer>
  </div>
</template>

<script>
export default {
  name: "updateAdmin",
  props: ["account", "person", "loading"],

  data() {
    return {
      calendar1: false,
    };
  },
  methods: {
    updateItem() {
      let body = {
        id: this.account.id,
        nama_lengkap: this.account.nama_lengkap,
        no_hp: this.person.no_hp,
        alamat: this.person.alamat,
        jenis_kelamin: this.person.jenis_kelamin,
        posisi: this.person.posisi,
        // profesi: this.person.profesi,
        tanggal_lahir: this.person.tanggal_lahir,
      };
      this.$emit("update", body);
    },
  },
};
</script>

<style></style>
